<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <logo />

        <h2 class="brand-text text-primary ml-1">
          VTelehealth
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Quên mật khẩu? 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Nhập địa chỉ email bạn đã đăng ký với VTelehealth. Sau đó vui lòng nhập <strong>mã xác nhận</strong> mà bạn nhận được.
          </b-card-text>

          <!-- form -->
          <validation-observer ref="forgotPasswordForm">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                label="Email"
              >
                <template
                  v-slot:label
                >
                  Email
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="emailAddress"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-phone"
                    v-model="emailAddress"
                    :state="errors.length > 0 ? false : null"
                    name="forgot-password-phone"
                    placeholder="username@mail.com"
                    :readonly="status===1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="status === 1"
                label="Mã xác nhận"
              >
                <template
                  v-slot:label
                >
                  Mã xác nhận
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="pin"
                  rules="required"
                >
                  <b-form-input
                    id="forgot-password-phone"
                    v-model="pin"
                    :state="errors.length > 0 ? false : null"
                    name="pin"
                    placeholder="6886"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- password -->
              <b-form-group
                v-if="status === 1"
                label="Mật khẩu"
              >
                <template
                  v-slot:label
                >
                  Mật khẩu
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Mật khẩu"
                  vid="password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                v-if="status === 1"
                label="Xác nhận mật khẩu"
              >
                <template
                  v-slot:label
                >
                  Xác nhận mật khẩu
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Xác nhận mật khẩu"
                  vid="password_confirm"
                  rules="required|password|confirmed:password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password_confirm"
                      v-model="passwordConfirm"
                      class="form-control-merge"
                      :type="passwordConfirmFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="password_confirm"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordConfirmToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordConfirmVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
              >
                {{ status === 0 ? "Lấy mã xác nhận": "Đổi mật khẩu" }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{ name: 'auth-login' }">
              <feather-icon icon="ChevronLeftIcon" /> Quay về đăng nhập
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import Logo from '@/layouts/components/Logo.vue';
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue';
import { required, email } from '@validations';
// eslint-disable-next-line import/no-cycle
import store from '@/store/index';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useJwt from '@/auth/jwt/useJwt';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import { togglePasswordConfirmVisibility } from '@/layouts/mixins/forms';

export default {
  components: {
    Logo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
  },
  mixins: [togglePasswordVisibility, togglePasswordConfirmVisibility],
  data() {
    return {
      status: 0,
      emailAddress: '',
      pin: '',
      password: '',
      passwordConfirm: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg');
        return this.sideImg;
      }
      return this.sideImg;
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    passwordConfirmToggleIcon() {
      return this.passwordConfirmFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },

  methods: {
    validationForm() {
      this.$refs.forgotPasswordForm.validate().then(success => {
        if (success) {
          if (this.status === 0) {
            useJwt.requestOTPByEmail({ email: this.emailAddress })
              .then(() => {
                this.status = 1;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Thông báo',
                    text: 'Vui lòng kiểm tra email và nhập mã xác nhận vào ô bên dưới để lấy lại mật khẩu',
                    icon: 'SmartphoneIcon',
                    variant: 'success',
                  },
                });
              })
              .catch(error => {
                if (error.response && error.response.status === 422 && error.response.data && error.response.data.errors) {
                  this.$refs.forgotPasswordForm.setErrors(error.response.data.errors);
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Thông báo',
                      text: error.response.data.message ? error.response.data.message : 'Có lỗi xảy ra, vui lòng thử lại sau',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  });
                }
              });
          } else {
            useJwt.resetPasswordWithEmailCodeOTP({ code: this.pin, password: this.password, email: this.emailAddress })
              .then(() => {
                this.$router
                  .replace({ name: 'auth-login' })
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: `Thông báo`,
                        icon: 'UserIcon',
                        variant: 'success',
                        text: `Bạn đã đổi mật khẩu thành công. Vui lòng đăng nhập để tiếp tục sử dụng dịch vụ!`,
                      },
                    });
                  });
              })
              .catch(error => {
                if (error.response && error.response.status === 422 && error.response.data && error.response.data.errors) {
                  this.$refs.forgotPasswordForm.setErrors(error.response.data.errors);
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Thông báo',
                      text: error.response.data.message ? error.response.data.message : 'Có lỗi xảy ra, vui lòng thử lại sau',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  });
                }
              });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
