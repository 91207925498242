var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('logo'),_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v(" VTelehealth ")])],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Forgot password V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Quên mật khẩu? 🔒 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Nhập địa chỉ email bạn đã đăng ký với VTelehealth. Sau đó vui lòng nhập "),_c('strong',[_vm._v("mã xác nhận")]),_vm._v(" mà bạn nhận được. ")]),_c('validation-observer',{ref:"forgotPasswordForm"},[_c('b-form',{staticClass:"auth-forgot-password-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Email"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Email "),_c('span',{staticClass:"text-danger"},[_vm._v(" (*) ")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"emailAddress","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"forgot-password-phone","state":errors.length > 0 ? false : null,"name":"forgot-password-phone","placeholder":"username@mail.com","readonly":_vm.status===1},model:{value:(_vm.emailAddress),callback:function ($$v) {_vm.emailAddress=$$v},expression:"emailAddress"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.status === 1)?_c('b-form-group',{attrs:{"label":"Mã xác nhận"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Mã xác nhận "),_c('span',{staticClass:"text-danger"},[_vm._v(" (*) ")])]},proxy:true}],null,false,1651896595)},[_c('validation-provider',{attrs:{"name":"pin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"forgot-password-phone","state":errors.length > 0 ? false : null,"name":"pin","placeholder":"6886"},model:{value:(_vm.pin),callback:function ($$v) {_vm.pin=$$v},expression:"pin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3815026185)})],1):_vm._e(),(_vm.status === 1)?_c('b-form-group',{attrs:{"label":"Mật khẩu"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Mật khẩu "),_c('span',{staticClass:"text-danger"},[_vm._v(" (*) ")])]},proxy:true}],null,false,2188162057)},[_c('validation-provider',{attrs:{"name":"Mật khẩu","vid":"password","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,204904664)})],1):_vm._e(),(_vm.status === 1)?_c('b-form-group',{attrs:{"label":"Xác nhận mật khẩu"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Xác nhận mật khẩu "),_c('span',{staticClass:"text-danger"},[_vm._v(" (*) ")])]},proxy:true}],null,false,4161073206)},[_c('validation-provider',{attrs:{"name":"Xác nhận mật khẩu","vid":"password_confirm","rules":"required|password|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password_confirm","type":_vm.passwordConfirmFieldType,"state":errors.length > 0 ? false : null,"name":"password_confirm","placeholder":"············"},model:{value:(_vm.passwordConfirm),callback:function ($$v) {_vm.passwordConfirm=$$v},expression:"passwordConfirm"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordConfirmToggleIcon},on:{"click":_vm.togglePasswordConfirmVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1102580120)})],1):_vm._e(),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":""}},[_vm._v(" "+_vm._s(_vm.status === 0 ? "Lấy mã xác nhận": "Đổi mật khẩu")+" ")])],1)],1),_c('p',{staticClass:"text-center mt-2"},[_c('b-link',{attrs:{"to":{ name: 'auth-login' }}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}}),_vm._v(" Quay về đăng nhập ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }